import React from "react";
import { injectIntl } from "gatsby-plugin-intl";
import { graphql } from "gatsby";

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Section from "../components/section";
import Partners from "../components/partners";
import ImageColumns from "../components/image-columns";
import Banner from "../components/banner";
import OurClients from "../components/our-clients";

import "../styles/clients.less";

export const segments = graphql`
    query ClientsNewQuery
    {
        allSegmentsJson
        {
            edges
            {
                node
                {
                    colSize
                    className
                    imageName
                    imagePath
                    imageAlt
                    title
                }
            }
        },
    }
`;

const Clients = (props) => {
  return <Layout>
    <SEO 
      title={props.intl.formatMessage({id: "Our Clients - Adventurees Alliance"})} 
      description={props.intl.formatMessage({id: "Who contracts our products and some of the organizations that trust us"})}
      location={props.location}
    />

    <Banner 
        className="banner" 
        filename="clients-banner.png"
        title={props.intl.formatMessage({id: "Find out how we help our customers"})} 
        subtitle={props.intl.formatMessage({id: "We will be happy to meet with you to better understand how we can help you"})}
    />

    <Section title={props.intl.formatMessage({id: "Who hires our products?"})}>
      <ImageColumns columns={props.data.allSegmentsJson.edges} className="services" />
    </Section>

    <OurClients/>

    <Section>
      <Partners />
    </Section>

    <OurClients onlyImg/>

  </Layout>
}

export default injectIntl(Clients);